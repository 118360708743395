import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	ButtonToolbar,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	Popover,
	PopoverBody,
	PopoverHeader,
} from 'reactstrap';
import EmojiContainer from '../../../../../Partials/Shared/emoticons/emoji-container';
import { PASTE_OPTION, SpecialCommands } from './collaborative-editor';
import { DATA_QA_ATTRIBUTES } from './data-qa.attributes';
import TableConfig from './table-config';

import '../../../style/collaborative-editor.scss';

export enum ToolbarAction {
	BOLD = 'bold',
	ITALIC = 'italic',
	UNDERLINE = 'underline',
	STRIKETHROUGH = 'strikethrough',
	TEXT_ALIGN_LEFT = 'text-align-left',
	TEXT_ALIGN_CENTER = 'text-align-center',
	TEXT_ALIGN_RIGHT = 'text-align-right',
	TEXT_ALIGN_JUSTIFY = 'text-align-justify',
	LINK = 'link',
	TABLE = 'table',
	PARAGRAPH = 'paragraph',
	HEADING = 'heading',
	UNORDERED_LIST = 'unordered-list',
	ORDERED_LIST = 'ordered-list',
	BLOCKQUOTE = 'blockquote',
	CODE_BLOCK = 'code-block',
	UNDO = 'undo',
	REDO = 'redo',
	CONTENT_BLOCKS = 'content-blocks',
	HEADING_TYPE = 'heading-type',
	TRANSFORM_TO = 'transform-to',
	TRANSFORM_TO_HEADING = 'transform-to-heading',
	TRANSFORM_TO_PARAGRAPH = 'transform-to-paragraph',
	TRANSFORM_TO_BLOCKQUOTE = 'transform-to-blockquote',
	HIGHLIGHT = 'highlight',
	SPECIAL_CHARACTERS = 'special-characters',
	PASTE_WORD_FORMATTING = 'paste-word-formatting',
	PASTE_ALL_FORMATTING = 'paste-all-formatting',
	PASTE_NO_FORMATTING = 'paste-no-formatting',
}

// The values in this enum are dependent on the names of Tiptap's nodes and marks.
export enum TiptapAction {
	BOLD = 'bold',
	ITALIC = 'italic',
	UNDERLINE = 'underline',
	STRIKETHROUGH = 'strike',
	TEXT_ALIGN = 'textAlign',
	LINK = 'link',
	TABLE = 'table',
	PARAGRAPH = 'paragraph',
	HEADING = 'heading',
	UNORDERED_LIST = 'bulletList',
	ORDERED_LIST = 'orderedList',
	BLOCKQUOTE = 'blockquote',
	CODE_BLOCK = 'codeBlock',
	HIGHLIGHT = 'highlight',
	TEXT_STYLE = 'textStyle',
}

export enum HighlightType {
	MARKER = 'marker',
	PEN = 'pen',
}

export const HIGHLIGHT_COLORS = [
	{ label: 'orange', color: 'rgb(254, 133, 11)', type: HighlightType.MARKER },
	{ label: 'pink', color: 'rgb(252, 120, 153)', type: HighlightType.MARKER },
	{ label: 'dark-pink', color: 'rgb(243, 89, 94)', type: HighlightType.MARKER },
	{ label: 'yellow', color: 'rgb(253, 253, 119)', type: HighlightType.MARKER },
	{ label: 'green', color: 'rgb(98, 249, 98)', type: HighlightType.MARKER },
	{ label: 'blue', color: 'rgb(114, 204, 253)', type: HighlightType.MARKER },
	{ label: 'orange', color: 'rgb(254, 133, 11)', type: HighlightType.PEN },
	{ label: 'light-red', color: 'rgb(237, 33, 41)', type: HighlightType.PEN },
	{ label: 'red', color: 'rgb(231, 19, 19)', type: HighlightType.PEN },
	{ label: 'dark-red', color: 'rgb(184, 0, 0)', type: HighlightType.PEN },
	{ label: 'green', color: 'rgb(18, 138, 0)', type: HighlightType.PEN },
	{ label: 'yellow', color: 'rgb(255, 223, 27)', type: HighlightType.PEN },
];

export interface EditorToolbarProps {
	editor: Editor | null;
	linkClicked: { doNotAutoLink: boolean; href: string; openInNewTab: boolean } | null;
	actions?: ToolbarAction[];
	onSpecialCommand?: (command: SpecialCommands, value: any) => void;
	showOnFocus?: boolean;
}

const HEADING_LEVELS = [1, 2, 3, 4];

const getActiveHeadingLevel = (editor: Editor | null): number | null => {
	if (!editor) {
		return null;
	}

	for (const level of HEADING_LEVELS) {
		if (editor.isActive(TiptapAction.HEADING, { level })) {
			return level;
		}
	}

	return null;
};

const CollaborativeEditorToolbar: React.FC<EditorToolbarProps> = ({ editor, linkClicked, actions, onSpecialCommand, showOnFocus }) => {
	const [t] = useTranslation();
	const [isLinkPopoverOpened, setIsLinkPopoverOpened] = useState(false);
	const [href, setHref] = useState('');
	const [prevHref, setPrevHref] = useState('');
	const [doNotAutoLink, setDoNotAutoLink] = useState(false);
	const [openInNewTab, setOpenInNewTab] = useState(false);
	const [isTablePopoverOpened, setIsTablePopoverOpened] = useState(false);
	const [isHeadingTypeDropdownOpened, setIsHeadingTypeDropdownOpened] = useState(false);
	const [isTransformToDropdownOpened, setIsTransformToDropdownOpened] = useState(false);
	const [isHighlightDropdownOpened, setIsHighlightDropdownOpened] = useState(false);
	const [isTableActionsDropdownOpened, setIsTableActionsDropdownOpened] = useState(false);
	const [selectedHighlightColor, setSelectedHighlightColor] = useState<typeof HIGHLIGHT_COLORS[number]>(HIGHLIGHT_COLORS[0]);
	const [pasteOption, setPasteOption] = useState(localStorage.getItem(PASTE_OPTION) || ToolbarAction.PASTE_WORD_FORMATTING);

	const toggleLinkButtonId = useRef('toggleLinkButton' + Math.random().toString(36).substring(7));
	const linkUrlInputId = useRef('linkUrlInput' + Math.random().toString(36).substring(7));
	const toggleTableButtonId = useRef('toggleTableButton' + Math.random().toString(36).substring(7));

	const activeHeadingLevel = getActiveHeadingLevel(editor);

	const onLinkChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setHref(event.target.value);
	};

	const isActionEnabled = useCallback(
		(action: ToolbarAction) => {
			return actions ? actions.includes(action) : true;
		},
		[actions],
	);

	useEffect(() => {
		if (!linkClicked) {
			return;
		}

		setDoNotAutoLink(linkClicked.doNotAutoLink);
		setPrevHref(linkClicked.href);
		setHref(linkClicked.href);
		setOpenInNewTab(linkClicked.openInNewTab);
		setIsLinkPopoverOpened(true);
	}, [linkClicked]);

	useEffect(() => {
		if (!isLinkPopoverOpened) {
			setPrevHref('');
			setDoNotAutoLink(false);
			setHref('');
			setOpenInNewTab(false);
		} else if (!href) {
			const implicitHref = (editor && editor.getAttributes('link').href) || '';

			setHref(implicitHref);
		}
	}, [isLinkPopoverOpened, href, editor]);

	useEffect(() => {
		if (!editor || !isActionEnabled(ToolbarAction.HIGHLIGHT)) {
			return;
		}

		editor.on('transaction', () => {
			const highlightColor = HIGHLIGHT_COLORS.find((highlightColorEntry) =>
				editor.isActive(highlightColorEntry.type === HighlightType.MARKER ? TiptapAction.HIGHLIGHT : TiptapAction.TEXT_STYLE, {
					color: highlightColorEntry.color,
				}),
			);

			if (highlightColor) {
				setSelectedHighlightColor(highlightColor);
			}
		});

		return () => {
			editor.off('transaction');
		};
	}, [editor, isActionEnabled(ToolbarAction.HIGHLIGHT)]);

	useEffect(() => {
		localStorage.setItem(PASTE_OPTION, pasteOption);
	}, [pasteOption]);

	useEffect(() => {
		const storageHandler = (event: StorageEvent) => {
			if (event.key === PASTE_OPTION && event.newValue) {
				setPasteOption(event.newValue);
			}
		};

		window.addEventListener('storage', storageHandler);

		return () => window.removeEventListener('storage', storageHandler);
	}, []);

	if (!editor) {
		return null;
	}

	const applyLink = () => {
		editor.chain().focus().extendMarkRange(TiptapAction.LINK).run();

		const { from, to } = editor.state.selection;

		if (from === to) {
			// Insert text and then set it as a link
			editor.chain().focus().insertContent({ type: 'text', text: href }).run();

			editor
				.chain()
				.focus()
				.setTextSelection({ from: from, to: from + href.length })
				.setLink({ href, target: openInNewTab ? '_blank' : '_self', class: doNotAutoLink ? 'autolink-disabled' : undefined })
				.run();
		} else {
			editor.commands.setLink({ href, target: openInNewTab ? '_blank' : '_self', class: doNotAutoLink ? 'autolink-disabled' : undefined });
		}

		setIsLinkPopoverOpened(false);
	};

	const removeLink = () => {
		editor.chain().focus().extendMarkRange(TiptapAction.LINK).unsetLink().run();
		setIsLinkPopoverOpened(false);
	};

	const onTableConfigApply = (rows: number, cols: number) => {
		editor.chain().focus().insertTable({ rows, cols, withHeaderRow: false }).run();
		setIsTablePopoverOpened(false);
	};

	return (
		<div
			className={classNames('collaborative-editor-toolbar', {
				'show-on-focus': showOnFocus,
			})}
			data-qa={DATA_QA_ATTRIBUTES.TOOLBAR}
		>
			<ButtonToolbar>
				{isActionEnabled(ToolbarAction.HEADING_TYPE) && (
					<>
						<Dropdown isOpen={isHeadingTypeDropdownOpened} toggle={() => setIsHeadingTypeDropdownOpened((prev) => !prev)}>
							<DropdownToggle caret className={classNames({ active: isHeadingTypeDropdownOpened })}>
								{t('heading') + (activeHeadingLevel ? ' ' + activeHeadingLevel : '')}
							</DropdownToggle>
							<DropdownMenu>
								{HEADING_LEVELS.map((level) => (
									<DropdownItem
										style={{ fontSize: 1 / level + 0.5 + 'em', fontWeight: 'bold' }}
										key={'heading' + level}
										active={activeHeadingLevel === level}
										onClick={() =>
											editor
												.chain()
												.focus()
												.toggleHeading({ level: level as Level })
												.run()
										}
									>
										{t('heading') + ' ' + level}
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>

						<div className='toolbar-separator' />
					</>
				)}
				{isActionEnabled(ToolbarAction.BOLD) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.BOLD) })}
						value={ToolbarAction.BOLD}
						aria-label='Bold'
						onClick={() => {
							if (editor.isActive(TiptapAction.BOLD)) {
								editor.chain().focus().extendMarkRange(TiptapAction.BOLD).unsetBold().run();
							} else {
								editor.chain().focus().toggleBold().run();
							}
						}}
						disabled={!editor.can().chain().focus().toggleBold().run()}
						aria-pressed={editor.isActive(TiptapAction.BOLD)}
						title={t('bold')}
					>
						<i className='fa-bold fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.ITALIC) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.ITALIC) })}
						value={ToolbarAction.ITALIC}
						aria-label='Italic'
						onClick={() => {
							if (editor.isActive(TiptapAction.ITALIC)) {
								editor.chain().focus().extendMarkRange(TiptapAction.ITALIC).unsetItalic().run();
							} else {
								editor.chain().focus().toggleItalic().run();
							}
						}}
						disabled={!editor.can().chain().focus().toggleItalic().run()}
						aria-pressed={editor.isActive(TiptapAction.ITALIC)}
						title={t('italic')}
					>
						<i className='fa-italic fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.UNDERLINE) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.UNDERLINE) })}
						value={ToolbarAction.UNDERLINE}
						aria-label='Underline'
						onClick={() => {
							if (editor.isActive(TiptapAction.UNDERLINE)) {
								editor.chain().focus().extendMarkRange(TiptapAction.UNDERLINE).unsetUnderline().run();
							} else {
								editor.chain().focus().toggleUnderline().run();
							}
						}}
						disabled={!editor.can().chain().focus().toggleUnderline().run()}
						aria-pressed={editor.isActive(TiptapAction.UNDERLINE)}
						title={t('underline')}
					>
						<i className='fa-underline fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.STRIKETHROUGH) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.STRIKETHROUGH) })}
						value={ToolbarAction.STRIKETHROUGH}
						aria-label='Strikethrough'
						onClick={() => {
							if (editor.isActive(TiptapAction.STRIKETHROUGH)) {
								editor.chain().focus().extendMarkRange(TiptapAction.STRIKETHROUGH).unsetStrike().run();
							} else {
								editor.chain().focus().toggleStrike().run();
							}
						}}
						disabled={!editor.can().chain().focus().toggleStrike().run()}
						aria-pressed={editor.isActive(TiptapAction.STRIKETHROUGH)}
						title={t('strikethrough')}
					>
						<i className='fa-strikethrough fa' />
					</Button>
				)}

				<div className='toolbar-separator' />

				{isActionEnabled(ToolbarAction.TEXT_ALIGN_LEFT) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'left' }) })}
						value={ToolbarAction.TEXT_ALIGN_LEFT}
						aria-label='Align left'
						onClick={() => editor.chain().focus().setTextAlign('left').run()}
						disabled={!editor.can().chain().focus().setTextAlign('left').run()}
						aria-pressed={editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'left' })}
						title={t('align_left')}
					>
						<i className='fa-align-left fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.TEXT_ALIGN_CENTER) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'center' }) })}
						value={ToolbarAction.TEXT_ALIGN_CENTER}
						aria-label='Align center'
						onClick={() => editor.chain().focus().setTextAlign('center').run()}
						disabled={!editor.can().chain().focus().setTextAlign('center').run()}
						aria-pressed={editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'center' })}
						title={t('align_center')}
					>
						<i className='fa-align-center fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.TEXT_ALIGN_RIGHT) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'right' }) })}
						value={ToolbarAction.TEXT_ALIGN_RIGHT}
						aria-label='Align right'
						onClick={() => editor.chain().focus().setTextAlign('right').run()}
						disabled={!editor.can().chain().focus().setTextAlign('right').run()}
						aria-pressed={editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'right' })}
						title={t('align_right')}
					>
						<i className='fa-align-right fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.TEXT_ALIGN_JUSTIFY) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'justify' }) })}
						value={ToolbarAction.TEXT_ALIGN_JUSTIFY}
						aria-label='Justify'
						onClick={() => editor.chain().focus().setTextAlign('justify').run()}
						disabled={!editor.can().chain().focus().setTextAlign('justify').run()}
						aria-pressed={editor.isActive({ [TiptapAction.TEXT_ALIGN]: 'justify' })}
						title={t('align_justify')}
					>
						<i className='fa-align-justify fa' />
					</Button>
				)}

				<div className='toolbar-separator' />

				{isActionEnabled(ToolbarAction.LINK) && (
					<>
						<Button
							id={toggleLinkButtonId.current}
							className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.LINK) })}
							value={ToolbarAction.LINK}
							aria-label='Link'
							disabled={!editor.can().chain().focus().toggleLink({ href: '' }).run()}
							title={t('link')}
							aria-pressed={isLinkPopoverOpened || editor.isActive(TiptapAction.LINK)}
						>
							<i className='fa-link fa' />
						</Button>
						<Popover
							className='collaborative-editor-toolbar-popover'
							placement='bottom'
							isOpen={isLinkPopoverOpened}
							target={toggleLinkButtonId.current}
							toggle={() => {
								if (isLinkPopoverOpened) {
									setIsLinkPopoverOpened(false);
								} else if (!editor.isActive(TiptapAction.LINK)) {
									setIsLinkPopoverOpened(!isLinkPopoverOpened);
								} else if (editor.isActive(TiptapAction.LINK)) {
									editor.chain().focus().unsetLink().run();
								}
							}}
							trigger='legacy'
							tabIndex={0}
							boundariesElement='viewport'
						>
							<PopoverHeader>{t('link')}</PopoverHeader>
							<PopoverBody>
								<fieldset className='Fieldset'>
									<div className='popover-primary-settings'>
										<label className='Label' htmlFor={linkUrlInputId.current}>
											{t('enter_link')}
										</label>
										<Input onChange={onLinkChange} type='text' className='Input' id={linkUrlInputId.current} value={href} />
									</div>
									<div className='popover-secondary-settings'>
										<span>{t('do_not_auto_link')}</span>
										<i className={`fa fa-2x fa-toggle-${doNotAutoLink ? 'on' : 'off'}`} onClick={() => setDoNotAutoLink(!doNotAutoLink)} />
										<span>{t('open_in_new_tab')}</span>
										<i className={`fa fa-2x fa-toggle-${openInNewTab ? 'on' : 'off'}`} onClick={() => setOpenInNewTab(!openInNewTab)} />
									</div>
								</fieldset>
								<Button
									className='link-button'
									color='primary'
									onClick={applyLink}
									disabled={!editor.can().chain().focus().setLink({ href: '' }).run() || !href}
								>
									{t('apply')}
								</Button>
								<Button className='link-button' onClick={removeLink} disabled={!editor.isActive(TiptapAction.LINK) || !prevHref}>
									{t('remove')}
								</Button>
							</PopoverBody>
						</Popover>
					</>
				)}
				{isActionEnabled(ToolbarAction.TABLE) && (
					<>
						<Button
							id={toggleTableButtonId.current}
							className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.TABLE) })}
							value={ToolbarAction.TABLE}
							aria-label='Table'
							disabled={!editor.can().chain().focus().insertTable().run() || editor.isActive(TiptapAction.TABLE)}
							title={t('table')}
							aria-pressed={isTablePopoverOpened || editor.isActive(TiptapAction.TABLE)}
						>
							<i className='fa-table fa' />
						</Button>
						<Popover
							className='collaborative-editor-toolbar-popover'
							placement='bottom'
							isOpen={isTablePopoverOpened}
							target={toggleTableButtonId.current}
							toggle={() => setIsTablePopoverOpened(!isTablePopoverOpened)}
							trigger='legacy'
							tabIndex={0}
							boundariesElement='viewport'
						>
							<PopoverHeader>{t('table')}</PopoverHeader>
							<PopoverBody>
								<TableConfig onSelect={onTableConfigApply} />
							</PopoverBody>
						</Popover>

						<Dropdown isOpen={isTableActionsDropdownOpened} toggle={() => setIsTableActionsDropdownOpened((prev) => !prev)}>
							<DropdownToggle
								caret
								className={classNames({ active: isTableActionsDropdownOpened })}
								disabled={!editor.isActive(TiptapAction.TABLE)}
							>
								{t('table_actions.table_actions')}
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => editor.chain().focus().toggleHeaderRow().run()}>{t('table_actions.toggle_header_row')}</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().addRowBefore().run()}>{t('table_actions.insert_row_above')}</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().addRowAfter().run()}>{t('table_actions.insert_row_below')}</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().deleteRow().run()}>{t('table_actions.delete_row')}</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().toggleHeaderColumn().run()}>
									{t('table_actions.toggle_header_column')}
								</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().addColumnBefore().run()}>{t('table_actions.insert_column_left')}</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().addColumnAfter().run()}>{t('table_actions.insert_column_right')}</DropdownItem>
								<DropdownItem onClick={() => editor.chain().focus().deleteColumn().run()}>{t('table_actions.delete_column')}</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</>
				)}

				<div className='toolbar-separator' />

				{isActionEnabled(ToolbarAction.PARAGRAPH) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.PARAGRAPH) })}
						value={ToolbarAction.PARAGRAPH}
						aria-label='Paragraph'
						onClick={() => editor.chain().focus().setParagraph().run()}
						aria-pressed={editor.isActive(TiptapAction.PARAGRAPH)}
						title={t('paragraph')}
					>
						<i className='fa-paragraph fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.HEADING) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.HEADING, { level: 3 }) })}
						value={ToolbarAction.HEADING}
						aria-label='Heading'
						onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
						aria-pressed={editor.isActive(TiptapAction.HEADING, { level: 3 })}
						title={t('heading')}
					>
						<i className='fa-heading fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.UNORDERED_LIST) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.UNORDERED_LIST) })}
						value={ToolbarAction.UNORDERED_LIST}
						aria-label='Unordered list'
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						aria-pressed={editor.isActive(TiptapAction.UNORDERED_LIST)}
						title={t('unordered_list')}
					>
						<i className='fa-list-ul fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.ORDERED_LIST) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.ORDERED_LIST) })}
						value={ToolbarAction.ORDERED_LIST}
						aria-label='Ordered list'
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						aria-pressed={editor.isActive(TiptapAction.ORDERED_LIST)}
						title={t('ordered_list')}
					>
						<i className='fa-list-ol fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.BLOCKQUOTE) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.BLOCKQUOTE) })}
						value={ToolbarAction.BLOCKQUOTE}
						aria-label='Blockquote'
						onClick={() => editor.chain().focus().toggleBlockquote().run()}
						aria-pressed={editor.isActive(TiptapAction.BLOCKQUOTE)}
						title={t('blockquote')}
					>
						<i className='fa-quote-left fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.CODE_BLOCK) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: editor.isActive(TiptapAction.CODE_BLOCK) })}
						value={ToolbarAction.CODE_BLOCK}
						aria-label='Codeblock'
						onClick={() => editor.chain().focus().toggleCodeBlock().run()}
						aria-pressed={editor.isActive(TiptapAction.CODE_BLOCK)}
						title={t('codeblock')}
					>
						<i className='fa-code fa' />
					</Button>
				)}

				<div className='toolbar-separator' />

				{isActionEnabled(ToolbarAction.TRANSFORM_TO) && (
					<Dropdown isOpen={isTransformToDropdownOpened} toggle={() => setIsTransformToDropdownOpened((prev) => !prev)}>
						<DropdownToggle caret className={classNames({ active: isTransformToDropdownOpened })}>
							<i className='fa-rotate fa' />
						</DropdownToggle>
						<DropdownMenu>
							{isActionEnabled(ToolbarAction.TRANSFORM_TO_HEADING) &&
								HEADING_LEVELS.map((level) => (
									<DropdownItem
										key={'heading' + level}
										onClick={() => onSpecialCommand && onSpecialCommand(SpecialCommands.TRANSFORM_TO, 'Heading ' + level)}
									>
										{t('heading') + ' ' + level}
									</DropdownItem>
								))}
							{isActionEnabled(ToolbarAction.TRANSFORM_TO_PARAGRAPH) && (
								<DropdownItem onClick={() => onSpecialCommand && onSpecialCommand(SpecialCommands.TRANSFORM_TO, 'Paragraph')}>
									{t('paragraph')}
								</DropdownItem>
							)}
							{isActionEnabled(ToolbarAction.TRANSFORM_TO_BLOCKQUOTE) && (
								<DropdownItem onClick={() => onSpecialCommand && onSpecialCommand(SpecialCommands.TRANSFORM_TO, 'Quote')}>
									{t('quote')}
								</DropdownItem>
							)}
						</DropdownMenu>
					</Dropdown>
				)}

				{isActionEnabled(ToolbarAction.HIGHLIGHT) && (
					<Dropdown isOpen={isHighlightDropdownOpened} toggle={() => setIsHighlightDropdownOpened((prev) => !prev)}>
						<Button
							className='toolbar-toggle-item'
							title={t('colored_' + selectedHighlightColor.type, { color: t(selectedHighlightColor.label.replace('-', '_')) })}
							onClick={() => {
								const { color, type } = selectedHighlightColor;

								if (type === HighlightType.MARKER) {
									if (editor.isActive(TiptapAction.HIGHLIGHT, { color: color })) {
										editor.chain().focus().extendMarkRange(TiptapAction.HIGHLIGHT).unsetHighlight().run();
									} else {
										editor.chain().focus().setHighlight({ color: color }).run();
									}
								} else if (editor.isActive(TiptapAction.TEXT_STYLE, { color: color })) {
									editor.chain().focus().extendMarkRange(TiptapAction.TEXT_STYLE).unsetColor().run();
								} else {
									editor.chain().focus().setColor(color).run();
								}
							}}
							active={editor.isActive(selectedHighlightColor.type === HighlightType.MARKER ? TiptapAction.HIGHLIGHT : TiptapAction.TEXT_STYLE, {
								color: selectedHighlightColor.color,
							})}
							disabled={
								editor.isActive(TiptapAction.LINK) ||
								(editor.isActive(TiptapAction.TEXT_STYLE) && selectedHighlightColor.type === HighlightType.MARKER)
							}
						>
							<i
								className={`fa-${selectedHighlightColor.type === HighlightType.MARKER ? 'highlighter' : HighlightType.MARKER} fa`}
								style={{ color: selectedHighlightColor.color }}
							/>
						</Button>
						<DropdownToggle split className={classNames({ active: isHighlightDropdownOpened })} disabled={editor.isActive(TiptapAction.LINK)} />
						<DropdownMenu className='horizontal icons-only'>
							{HIGHLIGHT_COLORS.map((highlightColorEntry) => {
								const { label, color, type } = highlightColorEntry;

								return (
									<DropdownItem
										key={type + label}
										title={t('colored_' + type, { color: t(label.replace('-', '_')) })}
										onClick={() => {
											if (type === HighlightType.MARKER) {
												editor.chain().focus().setHighlight({ color }).run();
											} else {
												editor.chain().focus().setColor(color).run();
											}
										}}
										active={editor.isActive(type === HighlightType.MARKER ? TiptapAction.HIGHLIGHT : TiptapAction.TEXT_STYLE, { color })}
										disabled={editor.isActive(type === HighlightType.MARKER ? TiptapAction.TEXT_STYLE : TiptapAction.HIGHLIGHT)}
									>
										<i className={`fa-${type === HighlightType.MARKER ? 'highlighter' : HighlightType.MARKER} fa`} style={{ color }} />
									</DropdownItem>
								);
							})}
							<DropdownItem
								style={{ borderLeft: '1px solid #c8ced3' }}
								title={t('remove_highlight')}
								onClick={() =>
									editor
										.chain()
										.focus()
										.extendMarkRange(TiptapAction.HIGHLIGHT)
										.extendMarkRange(TiptapAction.TEXT_STYLE)
										.unsetHighlight()
										.unsetColor()
										.run()
								}
							>
								<i className='fa-eraser fa' style={{ color: 'black' }} />
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				)}

				{isActionEnabled(ToolbarAction.SPECIAL_CHARACTERS) && (
					<EmojiContainer
						t={t}
						setSelectionEnd={() => {}}
						inputRef={editor.view.dom}
						inputValue={''}
						selectionEnd={0}
						onChange={(value: string) => {
							editor.chain().focus().insertContent(value).run();
						}}
						calledFrom={editor.view.dom.id}
						closeOnSelect={true}
					/>
				)}

				{(isActionEnabled(ToolbarAction.PASTE_WORD_FORMATTING) ||
					isActionEnabled(ToolbarAction.PASTE_ALL_FORMATTING) ||
					isActionEnabled(ToolbarAction.PASTE_NO_FORMATTING)) && <div className='toolbar-separator' />}

				{isActionEnabled(ToolbarAction.PASTE_WORD_FORMATTING) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: pasteOption === ToolbarAction.PASTE_WORD_FORMATTING })}
						value={ToolbarAction.PASTE_WORD_FORMATTING}
						aria-label='Paste Word formatting'
						onClick={() => setPasteOption(ToolbarAction.PASTE_WORD_FORMATTING)}
						title={t('paste_word_formatting')}
					>
						<i className='fa-file-word fa-solid' />
					</Button>
				)}

				{isActionEnabled(ToolbarAction.PASTE_ALL_FORMATTING) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: pasteOption === ToolbarAction.PASTE_ALL_FORMATTING })}
						value={ToolbarAction.PASTE_ALL_FORMATTING}
						aria-label='Paste with formatting'
						onClick={() => setPasteOption(ToolbarAction.PASTE_ALL_FORMATTING)}
						title={t('paste_all_formatting')}
					>
						<i className='fa-paste fa-solid' />
					</Button>
				)}

				{isActionEnabled(ToolbarAction.PASTE_NO_FORMATTING) && (
					<Button
						className={classNames('toolbar-toggle-item', { active: pasteOption === ToolbarAction.PASTE_NO_FORMATTING })}
						value={ToolbarAction.PASTE_NO_FORMATTING}
						aria-label='Paste as plain text'
						onClick={() => setPasteOption(ToolbarAction.PASTE_NO_FORMATTING)}
						title={t('paste_no_formatting')}
					>
						<i className='fa-paste fa-regular' />
					</Button>
				)}

				<div className='toolbar-separator' />

				{isActionEnabled(ToolbarAction.UNDO) && (
					<Button
						className='toolbar-toggle-item'
						value={ToolbarAction.UNDO}
						aria-label='Undo'
						onClick={() => editor.chain().focus().undo().run()}
						disabled={!editor.can().chain().focus().undo().run()}
						title={t('undo')}
					>
						<i className='fa-undo fa' />
					</Button>
				)}
				{isActionEnabled(ToolbarAction.REDO) && (
					<Button
						className='toolbar-toggle-item'
						value={ToolbarAction.REDO}
						aria-label='Redo'
						onClick={() => editor.chain().focus().redo().run()}
						disabled={!editor.can().chain().focus().redo().run()}
					>
						<i className='fa-redo fa' />
					</Button>
				)}
			</ButtonToolbar>
		</div>
	);
};

export default CollaborativeEditorToolbar;
