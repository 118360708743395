import { Editor } from '@tiptap/core';
import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Input, Row } from 'reactstrap';
import { Subscription } from 'rxjs';
import { SportTypes } from '../../../../../constants/sport.types';
import { modelFootballConnectionToFootballConnectionResponse } from '../../../../../models/v2/football-connection/response-football-connection.mapper';
import { setAutoTaggingScope } from '../../../../../store/action-creators/BlockyActionCreator';
import { addSuggestedEntity } from '../../../../../store/action-creators/suggested-entities';
import CollaborativeEditor, {
	SpecialCommands,
} from '../../../../Pages/Live Blog/components/subcomponents/collaborative-editor/collaborative-editor';
import { ToolbarAction } from '../../../../Pages/Live Blog/components/subcomponents/collaborative-editor/collaborative-editor-toolbar';
import { getDefaultExtensions } from '../../../../Pages/Live Blog/helpers/collaborative-editor-extensions.helper';
import { generateFootballConnectionWithEntityType } from '../../../Sidebar/tags-refactored/helpers/suggested-entities.helper';
import { updateAutoTaggingScope } from './../../../../Resources/Articles/Helpers/ArticleHelper';
import { EditorTypes } from './../../constants/block.types';
import { autoTagService } from './../../subcomponents/blocky.component';
import EditorHelper from './helpers/editor-block-edit.helper';
import EditorTransformService from './services/editor-transform.service';
import EntityLinkingService from './services/entity-linking.service';
import LoaderUIService from './services/loader.ui-service';
import AutoLinkingLoader from './subcomponents/auto-linking-loader.component';
import ManualLinkingControls from './subcomponents/manual-linking.component';

export const loaderUiService = new LoaderUIService();

const COMMON_EDITOR_ACTIONS = [
	ToolbarAction.BOLD,
	ToolbarAction.ITALIC,
	ToolbarAction.UNDERLINE,
	ToolbarAction.LINK,
	ToolbarAction.TEXT_ALIGN_LEFT,
	ToolbarAction.TEXT_ALIGN_CENTER,
	ToolbarAction.TEXT_ALIGN_RIGHT,
	ToolbarAction.TEXT_ALIGN_JUSTIFY,
	ToolbarAction.SPECIAL_CHARACTERS,
	ToolbarAction.PASTE_WORD_FORMATTING,
	ToolbarAction.PASTE_ALL_FORMATTING,
	ToolbarAction.PASTE_NO_FORMATTING,
];

const EDITOR_ACTIONS = {
	paragraph: [
		...COMMON_EDITOR_ACTIONS,
		ToolbarAction.TRANSFORM_TO,
		ToolbarAction.TRANSFORM_TO_HEADING,
		ToolbarAction.TRANSFORM_TO_BLOCKQUOTE,
		ToolbarAction.HIGHLIGHT,
	],
	heading: [
		...COMMON_EDITOR_ACTIONS,
		ToolbarAction.HEADING_TYPE,
		ToolbarAction.TRANSFORM_TO,
		ToolbarAction.TRANSFORM_TO_PARAGRAPH,
		ToolbarAction.TRANSFORM_TO_BLOCKQUOTE,
		ToolbarAction.HIGHLIGHT,
	],
	quote: [
		...COMMON_EDITOR_ACTIONS,
		ToolbarAction.BLOCKQUOTE,
		ToolbarAction.TRANSFORM_TO,
		ToolbarAction.TRANSFORM_TO_HEADING,
		ToolbarAction.TRANSFORM_TO_PARAGRAPH,
		ToolbarAction.HIGHLIGHT,
	],
	list: [
		ToolbarAction.BOLD,
		ToolbarAction.ITALIC,
		ToolbarAction.UNDERLINE,
		ToolbarAction.LINK,
		ToolbarAction.SPECIAL_CHARACTERS,
		ToolbarAction.ORDERED_LIST,
		ToolbarAction.UNORDERED_LIST,
		ToolbarAction.PASTE_WORD_FORMATTING,
		ToolbarAction.PASTE_ALL_FORMATTING,
		ToolbarAction.PASTE_NO_FORMATTING,
	],
	table: [...COMMON_EDITOR_ACTIONS, ToolbarAction.TABLE],
};

const EMPTY_PARAGRAPH_CONTENT = '<p></p>';

const EDITOR_DEBOUNCE_DELAY = 500;

class EditorEditBlock extends Component {
	globalTagsServiceSubscription = new Subscription();

	editorSwitchType = {
		'Heading 1': EditorTypes.heading,
		'Heading 2': EditorTypes.heading,
		'Heading 3': EditorTypes.heading,
		'Heading 4': EditorTypes.heading,
		Paragraph: EditorTypes.paragraph,
		Quote: EditorTypes.quote,
	};

	editor = null; // The Tiptap editor instance

	initialEditorContent = null; // used for initial prefill of the Tiptap editor

	constructor(props) {
		super(props);

		if (typeof props.data.content === 'string') {
			this.initialEditorContent = props.data.content;
		}

		this.state = {
			isEnterListenerInit: false,
			cursorLastPosition: null,
			isLoadingAutoTag: false,
			linkingService: new EntityLinkingService(),
			tabIndex: '',
			id: '',
		};
	}

	setIsLoadingAutoTagState = (isLoading) => {
		this.setState({
			isLoadingAutoTag: isLoading,
		});
	};

	clearHeadingAttributesState = () => {
		this.setState({
			tabIndex: '',
			id: '',
		});
	};

	setToCorrectHeading(content, input) {
		if (content.includes('h4')) {
			return `<h4>${input}</h4>`;
		} else if (content.includes('h3')) {
			return `<h3>${input}</h3>`;
		} else if (content.includes('h2')) {
			return `<h2>${input}</h2>`;
		} else return `<h1>${input}</h1>`;
	}

	onEditorInputChange = (input) => {
		const { data, onChange, linkingConfig } = this.props;

		if (input.length === 0 && data && data.type) {
			switch (data.type) {
				case 'heading':
					let newContent = this.setToCorrectHeading(data.content, input);
					data.content = newContent;
					break;
				case 'paragraph':
					data.content = `<p>${input}</p>`;
					break;
				case 'quote':
					data.content = `<blockquote><p>${input}</p></blockquote>`;
					break;
			}
		} else if (data.type === 'heading') {
			if (input.startsWith('<p>')) {
				data.content = new EditorTransformService().transform(EditorTypes.paragraph, input, 'Heading 2');
				this.editor.commands.setContent(data.content);
			} else {
				data.content = input;
			}
		} else if (data.type === 'list') {
			// Removes the <p> tag automatically added by the Tiptap editor to list items
			data.content = input.replace(/<[uo]l>.*<\/[uo]l>/g, (match) => match.replace(/<p>/g, '').replace(/<\/p>/g, ''));
		} else {
			data.content = input;
		}

		if (data.content === null || data.content === undefined || data.content.length < 1 || data.content === EMPTY_PARAGRAPH_CONTENT) {
			data.tags = [];
		} else if (!linkingConfig.isManual) {
			this.state.linkingService.cancelAutoTagRequests();
		}

		onChange(data);
	};

	onEditorInputWithTagsChange = (input, tags) => {
		const { data, onChange } = this.props;
		const tempData = Object.assign({}, data);
		tempData.content = input;
		tempData.tags = tags;
		onChange(tempData);
	};

	onEditorCreate = (editor) => {
		const { blockPlaceholder, data } = this.props;

		this.editor = editor;

		if (blockPlaceholder.includes(data.placeholderName)) {
			this.automatedLinking();
		}

		this.initGlobalTagListener(editor);
	};

	onEditorKeyDown = (_view, event) => {
		if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
			const { data, onAddParagraph } = this.props;

			if ([EditorTypes.list, EditorTypes.table].includes(data.type)) {
				return;
			}

			if (data.type === EditorTypes.paragraph) {
				const editor = this.editor;

				if (!editor) {
					return;
				}

				const { state } = editor;
				const { from } = state.selection;
				// Splits the current block into two blocks based on the cursor position
				const firstFragment = state.doc.cut(0, from);
				const secondFragment = state.doc.cut(from);

				if (secondFragment.textContent === '') {
					localStorage.removeItem('new-paragraph-content');
				} else {
					const tempEditor = new Editor({ extensions: getDefaultExtensions(false) });

					tempEditor.commands.setContent(secondFragment.content.toJSON());
					localStorage.setItem('new-paragraph-content', tempEditor.getHTML());
					tempEditor.destroy();
					editor.commands.setContent(firstFragment.content.toJSON(), true);
				}

				this.automatedLinking();
			}

			const newParagraph = onAddParagraph();

			try {
				const newParagraphElement = document.getElementById(newParagraph.id);

				if (newParagraphElement && newParagraphElement.editor) {
					newParagraphElement.editor.commands.focus('start');
				}
			} catch (e) {
				console.log('Error focusing new paragraph', e);
			}

			event.preventDefault();
			return true;
		}
	};

	onEditorSpecialCommand = (command, value) => {
		if (command !== SpecialCommands.TRANSFORM_TO) {
			return;
		}

		const { data, onTypeChange } = this.props;
		const editorBlockData = JSON.parse(JSON.stringify(data));
		const transformService = new EditorTransformService();
		editorBlockData.content = transformService.transform(editorBlockData.type, data.content, value);
		editorBlockData.type = this.editorSwitchType[value];

		this.editor.commands.setContent(editorBlockData.content);

		if (onTypeChange) {
			onTypeChange(editorBlockData);
			this.clearHeadingAttributesState();
		}
	};

	componentWillUnmount() {
		this.globalTagsServiceSubscription.unsubscribe();
		this.globalTagsServiceSubscription = null;
		this.state.linkingService.cancelAutoTagRequests();
		EditorHelper.cleanupEditor(this.props.data.placeholderName, this.props.data.type);
		this.editor = null;
	}

	componentDidMount() {
		let content = this.props.data.content;
		let id = EditorHelper.extractID(content);
		let tabIndex = EditorHelper.extractTabIndex(content);

		if ((tabIndex && tabIndex.length > 0) || (id && id.length > 0)) {
			const editor = this.editor;

			this.setState({ id: id, tabIndex: tabIndex });
			EditorHelper.addAdditionalAttributesToHeading('tabindex', this.state.tabIndex, editor);
			EditorHelper.addAdditionalAttributesToHeading('id', this.state.id, editor);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { data } = this.props;
		const editor = this.editor;

		if (!editor) {
			return;
		}

		let toUpdateContent = false;

		if (prevState.tabIndex !== this.state.tabIndex) {
			EditorHelper.addAdditionalAttributesToHeading('tabindex', this.state.tabIndex, editor);
			toUpdateContent = true;
		}

		if (prevState.id !== this.state.id) {
			EditorHelper.addAdditionalAttributesToHeading('id', this.state.id, editor);
			toUpdateContent = true;
		}

		// when the data has id/tabindex attribute we should extract it from the content because of undo/redo functionality
		// (we should update the tabindex/id input with correct values after undo/redo)
		if (prevProps.data.type === 'heading') {
			const content = editor.getHTML();

			const stateId = EditorHelper.extractID(content);
			const stateTabIndex = EditorHelper.extractTabIndex(content);
			if (stateTabIndex !== this.state.tabIndex || stateId !== this.state.id) {
				this.setState({
					tabIndex: !!stateTabIndex ? stateTabIndex : '',
					id: !!stateId ? stateId : '',
				});
			}
		}

		if (toUpdateContent) {
			this.onEditorInputChange(editor.getHTML());
		}

		if (prevProps.data.content !== data.content && editor.getHTML() !== data.content) {
			editor.commands.setContent(data.content || EMPTY_PARAGRAPH_CONTENT);
		}
	}

	handleTabindexChange = (e) => {
		this.setState({ tabIndex: e.target.value });
	};

	handleIdChange = (e) => {
		this.setState({ id: e.target.value });
	};

	// Start AUTO-TAGGING Logic
	initGlobalTagListener = (editor) => {
		if (this.globalTagsServiceSubscription && !this.globalTagsServiceSubscription.closed) {
			this.globalTagsServiceSubscription.unsubscribe();
		}
		this.globalTagsServiceSubscription = autoTagService.autoTagSubject.subscribe((action) => {
			const { data } = this.props;

			const editorHelper = new EditorHelper();

			const tags = data.tags
				? data.tags.map((tag) => {
						tag['firstOnly'] = action;

						return tag;
				  })
				: [];

			if (editor && editorHelper.hasAutoTagLinkOptionsConfig() && tags.length > 0) {
				this.state.linkingService.addLinksToTextMultiTags(tags, data.content, data.tags, (obj) => {
					this.onEditorInputWithTagsChange(obj.text, obj.updatedTags);
				});
			}
		});
	};

	storeSuggestedEntitiesInRedux = (paragraphIdentificator, tags) => {
		if (tags && paragraphIdentificator) {
			// store suggested entities in redux
			try {
				tags.forEach((tag) => {
					if (tag.sport !== SportTypes.FOOTBALL) {
						return;
					}

					const tagAsFootballConnection = modelFootballConnectionToFootballConnectionResponse(tag);
					const result = generateFootballConnectionWithEntityType(
						paragraphIdentificator,
						tagAsFootballConnection.entity_type,
						tagAsFootballConnection,
					);
					this.props.addSuggestedEntity(result);
				});
			} catch (error) {}
		}
	};

	automatedLinking = () => {
		const { data, linkingConfig } = this.props;
		const editorData = this.editor.getHTML();
		const editorBlockData = JSON.parse(JSON.stringify(data));

		if (
			editorBlockData.content === null ||
			editorBlockData.content === undefined ||
			editorBlockData.content.length < 1 ||
			editorBlockData.content === EMPTY_PARAGRAPH_CONTENT
		) {
			return;
		}

		if (editorBlockData.type === EditorTypes.paragraph && linkingConfig.isLinkingEnabled) {
			this.setIsLoadingAutoTagState(true);
			this.state.linkingService
				.link(editorData, editorBlockData.tags, linkingConfig.config, editorBlockData.placeholderName)
				.then((linkedTextAndTags) => {
					this.storeSuggestedEntitiesInRedux(editorBlockData.placeholderName, linkedTextAndTags.tags);
					this.onEditorInputWithTagsChange(linkedTextAndTags.text, linkedTextAndTags.tags);
					this.setIsLoadingAutoTagState(false);
				})
				.catch(() => {
					this.setIsLoadingAutoTagState(false);
				});
		}
	};

	onTagsRequest = (withScope = true) => {
		this.setIsLoadingAutoTagState(true);
		const { data } = this.props;
		const text = data.content;

		if (!text || text.length === 0 || text === EMPTY_PARAGRAPH_CONTENT) {
			this.setIsLoadingAutoTagState(false);
			return;
		}

		this.state.linkingService
			.requestTags(text, data.placeholderName, withScope)
			.then((obj) => {
				this.storeSuggestedEntitiesInRedux(data.placeholderName, obj.tags);
				this.onEditorInputWithTagsChange(obj.text, obj.tags);
			})
			.finally(() => this.setIsLoadingAutoTagState(false));
	};

	onSingleTagInsert = (tag) => {
		const { data, autoTaggingScope } = this.props;

		updateAutoTaggingScope([tag], this.props.setAutoTaggingScope, autoTaggingScope);

		this.state.linkingService.addLinksToText(tag, data.content, data.tags, (obj) => {
			this.onEditorInputWithTagsChange(obj.text, obj.updatedTags);
		});
	};

	onMultipleTagsInsert = (tags) => {
		const { data, autoTaggingScope } = this.props;

		if (tags.length > 0) {
			updateAutoTaggingScope(tags, this.props.setAutoTaggingScope, autoTaggingScope);
		}

		this.state.linkingService.addLinksToTextMultiTags(tags, data.content, data.tags, (obj) => {
			this.onEditorInputWithTagsChange(obj.text, obj.updatedTags);
		});
	};

	// END AUTO-TAGGING LOGIC

	render() {
		const { data, linkingConfig, t } = this.props;
		const { isLoadingAutoTag } = this.state;
		const placeholderName = data.placeholderName ? data.placeholderName : 'tiptap-editor-fresh-init';

		return (
			<>
				<Row className='mb-1'>
					<Col>
						<CollaborativeEditor
							editorClassName={data.type}
							subDocumentId={placeholderName}
							content={this.initialEditorContent}
							editable
							actions={EDITOR_ACTIONS[data.type] || COMMON_EDITOR_ACTIONS}
							onCreate={this.onEditorCreate}
							onUpdate={debounce((editor) => {
								const htmlContent = editor.getHTML();

								this.onEditorInputChange(htmlContent);
							}, EDITOR_DEBOUNCE_DELAY)}
							onKeyDown={this.onEditorKeyDown}
							onSpecialCommand={debounce(this.onEditorSpecialCommand, EDITOR_DEBOUNCE_DELAY)}
							enableCode={false}
							showToolbarOnFocus
						/>
					</Col>
				</Row>
				{data.type === EditorTypes.paragraph && linkingConfig.isLinkingEnabled && linkingConfig.isManual && (
					<ManualLinkingControls
						onMultipleTagsInsert={this.onMultipleTagsInsert}
						isLoading={isLoadingAutoTag}
						onSingleTagInsert={this.onSingleTagInsert}
						tags={data.tags}
						requestTags={this.onTagsRequest}
						t={t}
						id={placeholderName}
						disabled={isLoadingAutoTag || !data.content || data.content.length === 0 || data.content === EMPTY_PARAGRAPH_CONTENT}
					/>
				)}
				{data.type === EditorTypes.paragraph && linkingConfig.isLinkingEnabled && !linkingConfig.isManual && (
					<AutoLinkingLoader id={data.placeholderName} t={t} />
				)}
				{data.type === EditorTypes.heading && (
					<Row>
						<Col>
							<Input
								id='heading-tabindex-prop'
								value={this.state.tabIndex}
								type='number'
								size='2'
								placeholder='TabIndex'
								autoComplete='off'
								autoCorrect='off'
								autoCapitalize='off'
								spellCheck='false'
								onChange={this.handleTabindexChange}
							/>
						</Col>
						<Col>
							<Input
								id='heading-id-prop'
								value={this.state.id}
								onChange={this.handleIdChange}
								size='2'
								placeholder='ID'
								autoComplete='off'
								autoCorrect='off'
								autoCapitalize='off'
								spellCheck='false'
							/>
						</Col>
					</Row>
				)}
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		blockPlaceholder: state.blocky.blockPlaceholder,
		autoTaggingScope: state.blocky.autoTaggingScope,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		addSuggestedEntity: (suggested) => dispatch(addSuggestedEntity(suggested)),
		setAutoTaggingScope: (scope) => dispatch(setAutoTaggingScope(scope)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorEditBlock);
